body{
  margin: 0;
  padding: 0;
  font-family: DM Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #c84116;
  background-color: white !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  width: 65%;
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#c84116, #c84116);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #c84116;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
  width: 75%;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
body{
  background-color: hsl(242deg 53.04% 10.9%);
}
.hero{
  padding: 8% 8%;
  padding-bottom: 0%;

}
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.hero .left-side{
  text-align: center;
}

.left-side h1 {
  font-size: 2.7rem;
  font-weight: bold;
  color: #c84116; /* Adjust color as needed */
  margin-bottom: 10px;
  white-space: initial; /* Ensures text stays in one line */
  overflow: hidden; /* Keeps text from overflowing */
  border-right: 0px solid rgba(255, 166, 0, 0.055); /* Cursor effect */
  animation: blinkCursor 0.75s steps(40) infinite;
}
.lottie-animation {
  width: 600px;  /* Default width */
  height: 600px; /* Default height */
}
.lottie-animation1 {
  width: auto;  /* Default width */
  height: auto; /* Default height */
}
/* Media query for tablets */
@media (max-width: 768px) {
  .lottie-animation {
    width: 300px;
    height: 300px;
  }
}
.left-side h2 {
  font-size: 1.4rem;
  color: white;
  margin-bottom: 5px;
}

@keyframes blinkCursor {
  from { border-right-color: orange; }
  to { border-right-color: transparent; }
}

.right-side img {
  width: 100%;
  max-width: 400px; /* Adjust based on your layout */
  height: auto;
}
img{
  border-radius: 16px;
}






.HowWorks {
  display: flex;
  align-items: center;

  color: #fff; /* White text */
  position: relative;
  padding: 2% 8%;
  flex-direction: row-reverse;
}

.left-section {
  flex: 1;

}

.left-section img {
  width: 100%;
  height: auto;
}

.right-section {
  flex: 1;
  padding: 20px;

}

.text-container {
  max-width: 400px; /* Adjust based on your design */
}

.text-container h1 {
  font-size: 2rem; /* Large font for main heading */
}

.text-container h2 {
  font-size: 1.2rem; /* Smaller font for secondary heading */
}

.text-container p {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 1.3rem;
}

.learn-more {
  display: block;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
}

.learn-more:hover {
  text-decoration: underline;
}
.Services {

  color: #fff; /* White text */
  padding: 0% 8%;
  text-align: center;
}

.service-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.card {
  background-color: #c84116; /* Dark grey card background */
  border-radius: 8px;
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(236, 234, 234, 0.2);
  margin: 20px;
  text-align: left;
}

.icon img {
  height: 50px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.card h3 {
  margin-top: 10px;
  color: white; /* Pinkish color for the title */
  font-size: 1.3rem;
}

.card p {
  font-size: 1rem;
  color: white; /* Lighter grey text */
}
.Contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  color: #fff; /* White text color */
  padding: 40px 8%;
  position: relative;
  overflow: hidden;
}
.Services .btn-group{
  justify-content: center;
}
.promo-message{
  width: 50%;
}
.promo-graphic{
  width: 50%;
}
.promo-message h1 {
  font-size: 2rem; /* Adjust size as needed */
  margin-bottom: 0.5rem;
}

.promo-message p {
  font-size: 1.2rem;
}

.promo-graphic img {
  width: 100%; /* Adjust size as needed */
}

.promo-action button {
  background-color: #ff4066; /* Pink button color */
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.promo-action button:hover {
  background-color: #e03554; /* Darker pink on hover */
}
.elementor-widget-container {
  color: white;
  font-size: 20px;
  padding: 0% 8%;
  padding-bottom: 2%;
}
.elementor-widget-container p{
  line-height: 22px;
  margin: 0;
}
.elementor  section:first-of-type {
  background-color: #c84116;
  padding: 10% 0%;
  color: white;
  padding-bottom: 5%;
  margin-bottom: 30px;
}
.elementor  section:first-of-type p{
color: white;
}
.elementor  section:first-of-type h2{
  color: white;
}
.elementor  section:first-of-type div b{
  color: white;
}
.elementor  section:first-of-type p a strong{
  color: white!important;
}
.elementor  section:first-of-type p  strong{
  color: white!important;
  line-height: 26px;
}
.elementor  section:first-of-type a{
  color: white!important;
}
a{
  text-decoration: none;
  color: white;
}
section p{
  color: white; 
}
.elementor p strong{
  color: white;

}
section ul li{
  font-size: 20px;
  color: white;
}
.elementor div b{
  color: #ffffff;

}
.elementor h4{
  color: #ffffff;
  font-size: 22px;
  margin: 10px;
  margin-left: 0px;
}
.elementor h2{
  margin: 0;
}
.elementor li strong{
  color: #ffffff;
}
.elementor p a{
  text-decoration: none;
  color: #ffffff;

}
.f-logo {
  width: 45%;
}
@media (max-width: 768px) {
  .Navbar {
    display: none;
  }

  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #0d195e;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
    background-color: white;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #c84116; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #c84116; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }

  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .topFixed img{
    width: 100%;
  }
  .left h1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}
.hero .left{
  padding: 10px;
  width: 90%;
  border: 1px solid rgb(73, 73, 73);
  height: 20vh;
}
.hero .right{
  padding: 10px;
  width: 90%;
  border: 1px solid rgb(73, 73, 73);
  height: 25vh;
}
.right p {
  font-size: 1.2rem;
  margin-bottom: 0;
  text-align: center;
}
.HeroSection{
  flex-direction: column;
  justify-content: inherit;
  height: 55vh;
}
.HowWorks{
  flex-direction: column-reverse;
}
.service-cards{
  flex-direction: column;
}
.card{
 margin: 12px;
  width: auto;
}
.Contact{
  flex-direction: column-reverse;
}
.promo-graphic{
  width: 100%;
}
.promo-message{
  width: 100%;
}
.promo-message h1{
  font-size: 1.6rem;
  text-align: center;
}
.Services .btn-group{
  display: none;
}
.Footer1 img {
  margin-top: 40px;
  width: 100%;
}
.hero .btn-group{
  display: none;
}
.f-logo {
  width: 100%;
}
}
